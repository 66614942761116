<template>
  <div>
    <CRow>
      <CCol lg="12">
          <addContent/>
      </CCol>
      <!-- <CCol lg="4">
        <CloudStorageChart />
        <QuickNoteForm />
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";
import addContent from "../../components/add_contents.vue";


export default {
  name: "Dashboard",
  components: {
    WidgetStats,
    UserProfileCard,
    CloudStorageChart,
    QuickNoteForm,
    addContent
  },
  created(){
    let user_id = this.$session.get('user_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    } 
          
  }
};
</script>
